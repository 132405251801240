/* requires:
polyfill.js
*/

const generateEmarsysUrl = (formFields, formId) => {
  const emarsysFormId = `f=${formId}&`,
    inp_1 = formFields.first_name ? `inp_1=${formFields.first_name}&` : "",
    inp_2 = formFields.last_name ? `inp_2=${formFields.last_name}&` : "",
    inp_3 = formFields.email ? `inp_3=${formFields.email}&` : "",
    inp_9 = formFields.title ? `inp_9=${formFields.title}&` : "",
    inp_11 = formFields.city ? `inp_11=${formFields.city}&` : "",
    inp_12 = formFields.state ? `inp_12=${formFields.state}&` : "",
    inp_13 = formFields.zip ? `inp_13=${formFields.zip}&` : "",
    inp_15 = formFields.phone ? `inp_15=${formFields.phone}&` : "",
    inp_18 = formFields.company ? `inp_18=${formFields.company}&` : "",
    // referring URL
    inp_2718 = formFields["00N1T00000AXeke"]
      ? `inp_2718=${formFields["00N1T00000AXeke"]}&`
      : "",
    // source URL
    inp_2721 = formFields["00N1T00000AXel8"]
      ? `inp_2721=${formFields["00N1T00000AXel8"].split('#')[0]}&`
      : "",
    // source domain
    inp_2726 = formFields["00N1T00000AXel3"]
      ? `inp_2726=${formFields["00N1T00000AXel3"]}&`
      : "",
    // gclid
    inp_2728 = formFields["00N1T00000AXef3"]
      ? `inp_2728=${formFields["00N1T00000AXef3"]}&`
      : "",
    // facebook lead
    inp_17833 = formFields["00N1T00000AXef2"]
      ? `inp_17833=${formFields["00N1T00000AXef2"]}&`
      : "",
    // bing lead
    inp_17832 = formFields["00N1T00000AXfJP"]
      ? `inp_17832=${formFields["00N1T00000AXfJP"]}&`
      : "",
    // clientID
    inp_18500 = formFields["00N1T00000AXfJU"]
      ? `inp_18500=${formFields["00N1T00000AXfJU"]}&`
      : "",
    // training needs or message
    inp_22194 = formFields["00N1T00000Axl4O"]
      ? `inp_22194=${formFields["00N1T00000Axl4O"]}&`
      : "",
    // message
    inp_22127 = formFields.message ? `inp_22127=${formFields.message}&` : "",
    // language
    inp_22195 = formFields["00N1T00000Aaegz"]
      ? `inp_22195=${formFields["00N1T00000Aaegz"]}&`
      : "",
    // submission form industry state
    inp_22196 = formFields["00N1T00000Aaegp"]
      ? `inp_22196=${formFields["00N1T00000Aaegp"]}&`
      : "",
    // exam type
    inp_23427 = formFields["00N1T00000Aaegu"]
      ? `inp_23427=${formFields["00N1T00000Aaegu"]}&`
      : "",
    // web_form
    inp_2722 = formFields["web_form"]
      ? `inp_2722=${formFields["web_form"]}&`
      : "",
    // Lead Source
    inp_2723 = formFields["lead_source"]
      ? `inp_2723=${formFields["lead_source"]}&`
      : "",
    // lead_status
    inp_2725 = formFields["status"] ? `inp_2725=${formFields["status"]}&` : "";

  const emarsysFormUrl =
    "https://link.theuniversityplatform.com/u/register.php?CID=817427910&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y&" +
    emarsysFormId +
    inp_1 +
    inp_2 +
    inp_3 +
    inp_9 +
    inp_11 +
    inp_12 +
    inp_13 +
    inp_15 +
    inp_18 +
    inp_2718 +
    inp_2721 +
    inp_2726 +
    inp_2728 +
    inp_17833 +
    inp_17832 +
    inp_18500 +
    inp_22194 +
    inp_22127 +
    inp_22195 +
    inp_22196 +
    inp_23427 +
    inp_2722 +
    inp_2723 +
    inp_2725;

  return emarsysFormUrl;
};

const sfForms = {
  init: function (formSetting) {
    const { theForm } = formSetting;
    if (!theForm) {
      return;
    }
    this.validateOnBlur(theForm);

    // listening for click on the submit button
    const submitBtn = theForm.querySelector("button[type=submit]");
    submitBtn.addEventListener(
      "click",
      function (e) {
        e.preventDefault();
        const isFormValid = sfForms.isFormValid(theForm);
        if (!isFormValid) {
          alert("The highlighted field/s are required.");
          return false;
        } else {
          sfForms.submit(formSetting);
        }
      },
      true
    );
  },

  validateOnBlur: function (theForm) {
    if (!theForm) {
      return false;
    }
    // realtime form fields validation using blur event
    const fields = theForm.querySelectorAll(".required");
    for (let field of fields) {
      const type = field.type;
      if (type === "email") {
        field.addEventListener(
          "blur",
          function (e) {
            const x = field.value;
            const re =
              /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(x)) {
              field.classList.add("is-invalid");
            } else {
              field.classList.remove("is-invalid");
            }
          },
          true
        );
      } else if (type === "tel") {
        field.addEventListener(
          "blur",
          function (e) {
            const x = field.value;
            const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
            if (!re.test(x)) {
              field.classList.add("is-invalid");
            } else {
              field.classList.remove("is-invalid");
            }
          },
          true
        );
      } else {
        field.addEventListener(
          "blur",
          function (e) {
            const x = field.value;
            if (!x || x.trim() == "") {
              field.classList.add("is-invalid");
            } else {
              field.classList.remove("is-invalid");
            }
          },
          true
        );
      }
    }
  },

  isFormValid: function (theForm) {
    if (!theForm) {
      return false;
    }
    // form fields validation during submission
    const fields = theForm.querySelectorAll(".required");
    let isFormValid = true;
    for (let field of fields) {
      const type = field.type;
      if (type === "email") {
        const x = field.value;
        const re =
          /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(x)) {
          field.classList.add("is-invalid");
          isFormValid = false;
        } else {
          field.classList.remove("is-invalid");
        }
      } else if (type === "tel") {
        const x = field.value;
        const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
        if (!re.test(x)) {
          field.classList.add("is-invalid");
          isFormValid = false;
        } else {
          field.classList.remove("is-invalid");
        }
      } else {
        const x = field.value;
        if (!x || x.trim() == "") {
          field.classList.add("is-invalid");
          isFormValid = false;
        } else {
          field.classList.remove("is-invalid");
        }
      }
    }
    return isFormValid;
  },

  submit: function (formSetting) {
    const { theForm, emarsysFormId } = formSetting;
    const submitBtn = theForm.querySelector("button[type=submit]");

    const readCookie = (name) => {
      let nameEQ = encodeURIComponent(name) + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0)
          return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
      return null;
    };

    const referringUrl = document.referrer || "";
    const sourceUrl = window.location.href || "";
    const sourceDomain = "https://" + window.location.host || "";
    const adwordsLead = readCookie("adwordsLead") || "";
    const facebookLead = readCookie("facebookLead") || "";
    const bingLead = readCookie("bingLead") || "";
    const clientID = readCookie("_ga")
      ? readCookie("_ga").split(".").slice(2).join(".")
      : "";

    // global hidden form fields
    const initialFields = {
      oid: "00D500000007hH8", // SF organization ID
      status: "New",
      lead_source: "Academy Website Form",
      recordType: "0121T000000AUqV", // Academy value
      "00N1T00000AXeke": referringUrl,
      "00N1T00000AXel8": sourceUrl,
      "00N1T00000AXel3": sourceDomain,
      "00N1T00000AXef3": adwordsLead, // this is the gclid value saved the cookie
      "00N1T00000AXef2": facebookLead,
      "00N1T00000AXfJP": bingLead,
      "00N1T00000AXfJU": clientID,
    };

    const formMsg = theForm.querySelector(".form-msg");
    const formRawData = new FormData(theForm);

    // add key value pairs to existing formFields from formRawData
    const formFields = { ...initialFields };
    for (let [key, value] of formRawData) {
      formFields[key] = value;
    }

    // add initianFields to formRawData
    for (let [key, value] of Object.entries(initialFields)) {
      formRawData.append(key, value);
    }

    // form data for submission
    const formData = new URLSearchParams(formRawData);

    const formActionUrl =
      "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";

    // submit form to Emarsys via iframe
    const submitToEmarsysIframe = () => {
      const emarsysUrl = generateEmarsysUrl(formFields, emarsysFormId) || "";
      const emarsysIframe = document.querySelector("#emarsys-iframe");
      // create hidden iFrame to send data to Emarsys
      if (emarsysIframe) {
        emarsysIframe.remove();
      }
      const iFrame = document.createElement("iframe");
      iFrame.setAttribute("id", "emarsys-iframe");
      iFrame.setAttribute("src", emarsysUrl);
      iFrame.setAttribute("width", "0");
      iFrame.setAttribute("height", "0");
      iFrame.setAttribute(
        "style",
        "margin: 0; padding: 0; width: 0; height: 0; opacity: 0;"
      );
      document.body.appendChild(iFrame);
    };

    // send form to Saleforce without return URL, then submit the form to Emarsys
    const sendToSFWithoutReturn = () => {
      submitBtn.innerHTML = "Submitting...";
      fetch(formActionUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      })
        .then((response) => response.text())
        .then((data) => {
          if (data.status !== "error") {
            submitToEmarsysIframe();

            // show form success message
            formMsg.classList.remove("alert", "alert-danger");
            formMsg.classList.add("alert", "alert-success");
            formMsg.innerHTML =
              data.inlineMessage || "Form submission successful.";
            theForm.reset();
            submitBtn.innerHTML = "Submit";
          } else if (data.status === "error") {
            console.error("Error:", data);
            console.error("Errors:", data.errors);
            // show form error message
            formMsg.classList.remove("alert", "alert-success");
            formMsg.classList.add("alert", "alert-danger");
            formMsg.innerHTML = `<span>${data.errors[0].message}</span>`;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    function sendToSFWithReturn() {
      submitBtn.innerHTML = "Submitting...";
      // listen to onformdata event that is fired when the form is submitted
      // we use this to modify the form data before it get submitted to SF
      theForm.onformdata = (e) => {
        const formData = e.formData;
        // modifies the form data to add the rest of the data needed
        for (let [key, value] of formRawData) {
          // set or update form fields wth values
          formData.set(key, value);
        }
      };

      // submit to Emarsys via fetching the page then submit form to SF
      const emarsysUrl = generateEmarsysUrl(formFields, emarsysFormId) || "";
      fetch(emarsysUrl, {
        method: "POST",
        mode: "no-cors",
      })
        .then((response) => response.text())
        .then((data) => {
          theForm.action = formActionUrl;
          theForm.submit();
          submitBtn.innerHTML = "Submit";
        })
        .catch((error) => {
          console.error("Error:", error);
          console.error("Error:", error.data);
        });
    }

    if (formFields.retURL !== "") {
      sendToSFWithReturn();
    } else {
      sendToSFWithoutReturn();
    }
  },
};
